import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import { GatsbyImage } from 'gatsby-plugin-image'


import Layout from '../components/layout'
import Hero from '../components/template-parts/hero'

import '../scss/program.scss'

const ProgramTemplate = ({ data: { program } }) => {
    const { programData: { details, testimonials, finePrint } } = program
    const isBrowser = typeof window !== "undefined"

    useEffect(() => {
       if (isBrowser) {
            if (window.location.pathname.includes('program/give-an-hour')) {
                window.location.href = 'https://giveanhour.org/'
            }

            if (window.location.pathname.includes('program/all-secure-foundation')) {
                window.location.href = 'https://allsecurefoundation.org/'
            }

            if (window.location.pathname.includes('program/usa-cares')) {
                window.location.href = 'https://usacares.org/'
            }

            if (window.location.pathname.includes('program/green-beret-foundation')) {
                window.location.href = 'https://greenberetfoundation.org/'
            }

            if (window.location.pathname.includes('program/boulder-crest')) {
                window.location.href = 'https://bouldercrest.org/'
            }

            if (window.location.pathname.includes('program/hope-for-the-warriors')) {
                window.location.href = 'https://www.hopeforthewarriors.org/'
            }

            if (window.location.pathname.includes('program/stop-soldier-suicide')) {
                window.location.href = 'https://stopsoldiersuicide.org/'
            }

            if (window.location.pathname.includes('program/k9s-for-warriors')) {
                window.location.href = 'https://k9sforwarriors.org/'
            }

            if (window.location.pathname.includes('program/national-center-for-equine-facilitated-therapy-nceft')) {
                window.location.href = 'https://nceft.org/'
            }

            if (window.location.pathname.includes('program/warfighter-outfitters-inc')) {
                window.location.href = 'https://warfighteroutfitters.org/'
            }

            if (window.location.pathname.includes('program/the-mighty-oaks-foundation')) {
                window.location.href = 'https://www.mightyoaksprograms.org/'
            }

            if (window.location.pathname.includes('program/the-travis-mills-foundation')) {
                window.location.href = 'https://www.travismills.org/'
            }

            if (window.location.pathname.includes('program/doose-outfitters-ministry')) {
                window.location.href = 'https://dooseoutfittersministry.com/'
            }

            if (window.location.pathname.includes('program/operation-tohidu')) {
                window.location.href = 'https://melwood.org/veterans-services'
            }

            if (window.location.pathname.includes('program/the-elk-institute-for-psychological-health-performance')) {
                window.location.href = 'http://elkinstitute.us/about-institute/'
            }
       }
    }, []);

    return (
        <Layout>
            <Seo post={program} />
            <Hero page={program} />
            {details?.length && details.map(detail => (

                <section className="program-details">
                    <div className="container">
                        <div className="row">
                            <div className="video-container">
                                {detail?.video && ReactPlayer.canPlay(detail?.video) ? (
                                    <div className="video-wrapper">
                                        <ReactPlayer className="video" url={detail?.video} light playing controls="true" />
                                    </div>
                                ) : (
                                    <div className="image-wrapper">
                                        {detail?.image?.localFile?.childImageSharp?.gatsbyImageData && (
                                            <GatsbyImage image={detail?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={detail?.image?.altText ? detail?.image?.altText : detail.title} />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="details">
                                <h2>{detail?.title && detail.title}</h2>
                                <div className="content">
                                    {detail?.content && parse(detail.content)}
                                </div>
                                {detail?.button && (
                                    <Link to={detail.button.url} className="button blue" target={detail.button.target}>
                                        {detail.button.title}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            ))}

            {finePrint && finePrint !== '' && (
                <section className="fine-print">
                    <div className="container">
                        {parse(finePrint)}
                    </div>
                </section>
            )}

            <section className="testimonials">
                <div className="container">
                    
                    {testimonials && testimonials.length && (
                        <Swiper modules={[Navigation]} spaceBetween={56} slidesPerView={1} autoHeight={true} navigation breakpoints={{
                            480: {
                                slidesPerView: 2
                            }
                        }}>
                            {testimonials.map(testimonial => (
                                <SwiperSlide>
                                    <div className="testimonial">
                                        <div className="content">
                                            {parse(testimonial.content)}
                                        </div>
                                        <div className="name">
                                            — {testimonial.name}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                </div>
            </section>
        </Layout>
    )
}

export default ProgramTemplate

export const programQuery = graphql`#graphql
    query ProgramById( $id: String! ) {
        program: wpProgram(id: { eq: $id }) {
            slug
            title
            programData {
                finePrint
                testimonials {
                    content
                    name
                }
                details {
                    video
                    title
                    image {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                    layout: FULL_WIDTH
                                    aspectRatio: 1.5
                                )
                            }
                        }
                    }
                    fieldGroupName
                    content
                    button {
                        target
                        title
                        url
                    }
                }
            }
            seo {
                title
                metaDesc
                focuskw
                metaKeywords
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
            hero {
                backgroundImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 2000
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, JPG]
                            )
                        }
                    }
                }
                title
                largeHero
                content
                button {
                    url
                    title
                    target
                }
            }
        }
    }
`
